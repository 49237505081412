import React from 'react';
import { object, bool } from 'prop-types';
import loadable from '@loadable/component';

import {
  GOLD_SUBSCRIPTION,
  CONTACT_US,
  SUBSCRIPTION_ROUTE,
  HOME_LOGGED_IN_ROUTE,
} from 'constants/navigation';
import {
  STARS_NAV,
  EMAIL_NAV,
  SUBSCRIPTION_NAV,
  HOME_NAV,
} from 'constants/font-awesome';

import useStyles from 'apputil/page-styles';

import { Grid, Typography } from '@material-ui/core';

const Page = loadable(() => import('components/page'));
const Loading = loadable(() => import('components/loading'));
const LazySubscriptionBanner = loadable(() => import('components/banners/in-app-banners/subscription'));
const LazyGoldSubscriptionView = loadable(() => import('views/subscription/gold'));
const LazyOverrideFooter = loadable(() => import('components/footer/override-footer'));

const SuspendedGoldSubscriptionPage = ({ loading, subscriptionPrices }) => {
  const styles = useStyles();

  return (
      <Page title="Gold"
        description="Gold Subscription (10,000 active loyalty cards)">
        <LazySubscriptionBanner/>
        <Typography variant="h2" component="h1" className={styles.heading} align="center">
          Gold Subscription
        </Typography>
        <Typography variant="h4" component="p" className={styles.headingMessage} align="center">
         10,000 active loyalty cards
        </Typography>
          {loading ? (
            <Grid container spacing={3}>
              <Loading/>
            </Grid>
          ) : (
        <LazyGoldSubscriptionView subscriptionPrices={subscriptionPrices}/>
          )}
        <LazyOverrideFooter
          navItems={[
            { route: HOME_LOGGED_IN_ROUTE, icon: HOME_NAV, message: 'Home' },
            { route: GOLD_SUBSCRIPTION, icon: STARS_NAV, message: 'Gold' },
            { route: SUBSCRIPTION_ROUTE, icon: SUBSCRIPTION_NAV, message: 'Subscription' },
            { route: CONTACT_US, icon: EMAIL_NAV, message: 'Contact' },
          ]}
          currentItem="Gold"
          />
      </Page>
  );
};

SuspendedGoldSubscriptionPage.propTypes = {
  loading: bool.isRequired,
  subscriptionPrices: object.isRequired,
};

export default SuspendedGoldSubscriptionPage;
