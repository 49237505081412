import theme, { isDark } from 'theme';
import { makeStyles } from '@material-ui/core/styles';

export const pageStyles = {
  heading: {
    marginTop: `${theme.spacing(4)}px !important`,
  },
  bodyHeader: {
    paddingTop: 0,
    paddingLeft: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: `${theme.spacing(2)}px !important`,
  },
  body1: {
    paddingTop: 0,
    paddingLeft: theme.spacing(1),
    marginBottom: `${theme.spacing(1)}px !important`,
  },
  body2: {
    paddingTop: 0,
    paddingLeft: theme.spacing(1),
    margin: `${theme.spacing(0.5)}px !important`,
  },
  body2Indent: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(2),
    margin: `${theme.spacing(0.5)}px !important`,
  },
  body2MarginBottom: {
    paddingTop: 0,
    paddingLeft: theme.spacing(2),
    marginTop: `${theme.spacing(0.5)}px !important`,
    marginLeft: `${theme.spacing(0.5)}px !important`,
    marginRight: `${theme.spacing(0.5)}px !important`,
    marginBottom: `${theme.spacing(4)}px !important`,
  },
  caption: {
    paddingTop: 0,
    paddingLeft: theme.spacing(2),
    marginTop: `${theme.spacing(0.5)}px !important`,
    marginLeft: `${theme.spacing(0.5)}px !important`,
    marginRight: `${theme.spacing(0.5)}px !important`,
    marginBottom: `${theme.spacing(2)}px !important`,
  },
  paper: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  }),
  fourFourContainer: {
    paddingTop: 0,
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0),
  },
  fourFourIcon: {
    textAlign: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.fontSize * 16,
    color: theme.palette.secondary.light,
  },
  fourFourHeader: {
    paddingTop: 0,
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(0),
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0),
  },
  fourFourMessage: {
    paddingTop: 0,
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(4),
    fontWeight: theme.typography.fontWeightBold,
  },
  workingHeader: {
    paddingTop: 0,
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0),
  },
  workingMessage: {
    paddingTop: 0,
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(4),
  },
  workingIcon: {
    textAlign: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.fontSize * 16,
    color: isDark()
      ? theme.palette.primary.dark : theme.palette.primary.light,
  },
};

export default makeStyles(pageStyles);
